import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from 'react-modal'; // React Modal library
import addFarm from '../../asset/images/addFarm.svg';
import rightArrow from '../../asset/images/rightArrow.svg';
import addIcon from '../../asset/images/addIcon.svg';
import leftNavArrow from '../../asset/images/leftNavArrow.svg';
import tick_Icon from '../../asset/images/tickIcon.svg';
import { useTranslation } from 'react-i18next';
Modal.setAppElement('#root'); // Set the app root element for accessibility

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ServicesTitle = styled.h2`
  margin-top: 10px;
  font-size: 20px;
  color: #000;
`;

const AddFarmButton = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  background: #00B800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: #00a300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background: #008f00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const AddFarmButtonIcon = styled.img`
  width: 20%;
  height: 40px;  
  padding-left: 1px;
`;

const AddFarmButtonTitle = styled.div`
  display: flex; 
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  font-family: Poppins;
  height: 100%;
  font-size: 19px;
  font-weight: 600;
  text-align: left; 
  color: #FFFFFF; 
  padding: 0 10px; 
`;

const FarmListContainer = styled.div`
`;

const Farm = styled.div`
  box-shadow: 10px 9.6px 23.04px 10px #0000001A;
  width: 98%;
  height: auto;
  min-height: 160px;
  margin-top: 20px;
  gap: 0px;
  border-radius: 12px;
  padding: 5px;
  background:white;
`;

const FarmNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-top: 20px;
`;

const FarmName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 20px;  
  padding-top: 2px;
`;

const FormDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 5px;
  padding-left: 20px;
  color: #474747;
`;


const ModalContent = styled.div`
padding: 20px;
background: #F8FAF5;
width: 100%;
height: 100%;
overflow-y: auto;
`;

const ModalTitle = styled.h2`
display:flex;
 
font-family: Poppins;
color: #1A1A1A;

font-family: Poppins;
font-size: 25.24px;
font-weight: bolder; /* Use 'bolder' for extra bold */
line-height: 23.09px;
text-align: left;

`;

const ModalForm = styled.form`
display: flex;
flex-direction: column;
gap: 16px;
`;

const ModalInput = styled.input`
width: 87%;
height: 46px;
padding-left:10px;  
border: 1px ; 
border-radius: 8px;

font-size: 14px;
font-family: Poppins;
background: #FFFFFF;
border: 1px solid #D9D9D9
`;
const ModalAreaInput = styled.input`
width: 50%;
height: 46px;
padding-left:10px;  

border-radius: 8px;
border: 1px solid #D9D9D9;
font-size: 16px;
font-family: Poppins;
background: #FFFFFF;

`;


const ModalLabel = styled.label`
color: #1A1A1A;
margin-bottom: 0px;
font-family: Poppins;
font-size: 16px;
font-weight: 900;
line-height: 18px;
text-align: left;

`;

const AddCropContainer = styled.div`
  width: 90%;
  
  height:50px;
  margin-top: 15px;
  
  
  display:flex,
  
  
  flex-direction: row;
  display:flex;
  border: 1px ; 
border-radius: 8px;

font-size: 14px;
font-family: Poppins;
background: #FFFFFF;
border: 1px solid #D9D9D9

`;

const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;
  
   

`;

const ModalCropInput = styled.input`
width: 90%;
height: 46px;
padding-left:10px;  
border: 1px ; 
border-radius: 8px;
outline:none;

font-size: 16px;
font-family: Poppins;
background: #FFFFFF;
border: 0px solid #D9D9D9
`;

const AddIcon = styled.div`
display: flex;
flex-wrap: wrap;
width:10%;

`;
const NavIcon = styled.div`
display: flex;
flex-wrap: wrap; 
width:12%;

`;


const CropAgeContainer = styled.div`
display: flex;
flex-wrap: wrap;
width:90%;
gap: 10px;
`;

const CropAgeButton = styled.button`
padding: 10px;
flex: 1;
min-width: calc(50% - 10px);
border-radius: 8px;
border: 1px solid #C9C9C9;
background-color: #fff;
font-size: 16px;
font-family: Poppins;
cursor: pointer;

&:hover {
  background-color: #E9F5E8;
}

&:active {
  background-color: #D1E9D2;
}

&.active {
  background-color: #00B800;
  color: white;
}
`;

const ModalButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 90%;
  
  padding-bottom: 20px;
  
`;

const ModalButton = styled.button`
  padding: 15px;
  background-color: #00B800;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-align: center;

  width: 100%;

  &:hover {
    background-color: #00a300;
  }

  &:active {
    background-color: #008f00;
  }
`;
const AreaInputContainer = styled.div`
display: flex;
width:90%;
gap: 10px;
`;

const AreaSelect = styled.select`
padding: 12px;
border-radius: 8px;
width:50%;
border: 1px solid #C9C9C9;
font-size: 14px;
font-family: Poppins;
background: #FFFFFF;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 14px;
text-align: left;

`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const CropCategory = styled.h3`
  font-family: Poppins;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const CropGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

const CropItem = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  
`;
const CropItemContainer = styled.div`
  display:flex;
  flex-direction:column;
  
`;
const CropItemName = styled.div`
margin-top:2px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
   width: 80px; 
`;



const CropImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TickIcon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 10px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;
const MyFarm = (props) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCropOpen, setIsModalCropOpen] = useState(false);
  const [selectedCropAge, setSelectedCropAge] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState(null); // To store the selected crop
  const [selectedFarm, setSelectedFarm] = useState(null); // To store the selected crop
  
  const [farmList, setFarmList] = useState([{
    "farmName": "Khet 1",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },
  {
    "farmName": "Khet 2",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },
  {
    "farmName": "Khet 2",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },
  {
    "farmName": "Khet 2",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },{
    "farmName": "Khet 2",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },
  {
    "farmName": "Khet 2",
    
      "currentAge": "20 - 30 Days",
      "currentCrop": "Paddy",
      "farmArea": "6 Bigha"
    
  },


  ])

  const { t, i18n } = useTranslation();
  const crops = [


    // Vegetables
    { id: 3, name: 'Carrot', category: 'Vegetables', image: 'https://t4.ftcdn.net/jpg/05/37/04/61/360_F_537046123_s8JVn2NrClPQDOryhSm8jonYZPfIzPRX.jpg' },
    { id: 6, name: 'Tomato', category: 'Vegetables', image: 'https://vertagro.blob.core.windows.net/vertagrimages/1677228480699Paddy%20Photo.jpeg' },
    { id: 7, name: 'Potato', category: 'Vegetables', image: 'https://t4.ftcdn.net/jpg/05/03/15/05/360_F_503150529_PImtWQ5wnPGzFQXtkEPRi3K7iSHq8d3o.jpg' },
    { id: 8, name: 'Onion', category: 'Vegetables', image: 'https://t4.ftcdn.net/jpg/05/06/15/87/360_F_506158748_Ycfd8zCCJHsJ2ZfFAfsu1Azrs8vTRbwt.jpg' },
    { id: 3, name: 'Carrot', category: 'Vegetables', image: 'https://t4.ftcdn.net/jpg/05/37/04/61/360_F_537046123_s8JVn2NrClPQDOryhSm8jonYZPfIzPRX.jpg' },


    // Fruits
    { id: 9, name: 'Apple', category: 'Fruits', image: 'https://t4.ftcdn.net/jpg/05/27/14/68/360_F_527146865_4uLpD4ZUwOW5qlj8Z3PPpgtF7seSQXbK.jpg' },
    { id: 10, name: 'Banana', category: 'Fruits', image: 'https://t4.ftcdn.net/jpg/05/27/32/80/360_F_527328040_PwbBHaIGuZh07RR1kOdQQQpOwwfdBGjy.jpg' },
    { id: 11, name: 'Grapes', category: 'Fruits', image: 'https://t4.ftcdn.net/jpg/04/76/86/73/360_F_476867341_qqvShJhYxFxR8vOgy7HlI1z6nOcCHyfJ.jpg' },
    { id: 12, name: 'Mango', category: 'Fruits', image: 'https://t4.ftcdn.net/jpg/05/02/88/99/360_F_502889927_Km1GpWEiGcnNKaU9kpNyZ5IVUibHcdQd.jpg' },

    // Legumes
    { id: 13, name: 'Peas', category: 'Legumes', image: 'https://t4.ftcdn.net/jpg/05/02/47/50/360_F_502475073_qsm4d9Lf8RMvN5X2NqU3LTGZybdCVXsk.jpg' },
    { id: 14, name: 'Lentils', category: 'Legumes', image: 'https://t4.ftcdn.net/jpg/03/00/76/78/360_F_300767844_WiuzZxueYW0Hknu7pZT52XlZG35UURkF.jpg' },
    { id: 15, name: 'Chickpeas', category: 'Legumes', image: 'https://t4.ftcdn.net/jpg/03/81/12/62/360_F_381126267_bnhPMpU3EyF4eDCuB7ZZGBZkrPvPeV7i.jpg' },
    { id: 16, name: 'Soybeans', category: 'Legumes', image: 'https://t4.ftcdn.net/jpg/03/87/85/96/360_F_387859631_Jo1AOs0d6JXmqHHgiIkdKAVHVKQaZkxI.jpg' }
  ];

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simulate loading delay
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeCropModal = () => {

    setIsModalCropOpen(false);
    setIsModalOpen(true);
  };

  const handleCropAgeSelect = (age) => {
    setSelectedCropAge(age);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const farmData = {
      farmName: formData.get('farmName'),
      currentCrop: formData.get('currentCrop'),
      cropAge: formData.get('cropAge'),
      area: formData.get('area'),
    };

    console.log('Farm data:', farmData);
    closeModal();
    // Handle form submission logic here
  };
  const handleCropSelect = (crop) => {
    setSelectedCrop(crop);
  };

  const handleSaveCropSelection = () => {
    // Save crop selection logic here
    closeCropModal();
  };


  return (
    <>
      {props.showAdd && <ServicesTitle style={{ marginTop: 20 }}>
        <TitleContainer>
          <ServicesTitle>{t('Add your farm')}</ServicesTitle>
        </TitleContainer>
      </ServicesTitle>
      }

      {props.showAdd && <AddFarmButton onClick={openModal}>
        <AddFarmButtonIcon src={addFarm} />
        <AddFarmButtonTitle>{t('Add your farm & crop')}</AddFarmButtonTitle>
      </AddFarmButton>
      }

      <FarmListContainer>
        {loading ? (
          <>
            <Skeleton height={160} style={{ marginBottom: 20, borderRadius: 12 }} />
            <Skeleton height={160} style={{ marginBottom: 20, borderRadius: 12 }} />
          </>
        ) : (
          <>
            
            {farmList && farmList.map((farm, index) => (
              <Farm style={selectedFarm==farm?{border:"2px solid #00B800",borderRadius:"8px"}:null} onClick={()=>{setSelectedFarm(farm)}}>
                <FarmNameContainer>
                  <FarmName>{farm.farmName}</FarmName>
                  {/* <MoreIcon src={rightArrow} /> */}
                </FarmNameContainer>
                <FormDetail>
                  Current age: <span style={{ marginLeft: 5 }}><b>{farm.currentAge}</b></span>
                </FormDetail>
                <FormDetail>
                  Current crop: <span style={{ marginLeft: 5 }}><b>{farm.currentCrop}</b></span>
                </FormDetail>
                <FormDetail>
                  Farm area: <span style={{ marginLeft: 5 }}><b>{farm.farmArea}</b></span>
                </FormDetail>
              </Farm> 
            ))} 
          </>
        )}
      </FarmListContainer>

      {/* Add Farm Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Farm"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          <ModalNavigation>
            <NavIcon >
              <img src={leftNavArrow} onClick={closeModal} />
            </NavIcon>
            <ModalTitle>{t('Add Farm')}</ModalTitle>
          </ModalNavigation>

          <ModalForm onSubmit={handleFormSubmit}>

            <ModalInput type="text" name="farmName" id="farmName" placeholder='Farm Name' required />


            <AddCropContainer onClick={() => { setIsModalCropOpen(true) }}>
              <ModalCropInput placeholder='Add your current crop' type="text" name="currentCrop" id="currentCrop" required />
              <AddIcon >
                <img src={addIcon} />
              </AddIcon>

            </AddCropContainer>


            <ModalLabel htmlFor="cropAge">Crop Age *</ModalLabel>
            <CropAgeContainer>
              {['-10 - 0 Days', '01 - 10 Days', '11 - 35 Days', '36 - 50 Days'].map((age) => (
                <CropAgeButton
                  key={age}
                  className={selectedCropAge === age ? 'active' : ''}
                  onClick={() => handleCropAgeSelect(age)}
                  type="button"
                >
                  {age}
                </CropAgeButton>
              ))}
            </CropAgeContainer>

            <ModalLabel htmlFor="area">Area *</ModalLabel>
            <AreaInputContainer>
              <ModalAreaInput type="number" name="area" id="area" required />
              <AreaSelect name="areaUnit" id="areaUnit" required>
                <option value="Bigha">Bigha</option>
                <option value="Acre">Acre</option>
                <option value="Hectare">Hectare</option>
              </AreaSelect>
            </AreaInputContainer>

            <ModalButtonContainer>
              <ModalButton type="submit">Save & Continue</ModalButton>
            </ModalButtonContainer>
          </ModalForm>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isModalCropOpen}
        onRequestClose={closeCropModal}
        contentLabel="Add Crop"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: '#8FCB42',
          },
        }}
      >
        <ModalContent>
          <ModalNavigation>
            <NavIcon >
              <img src={leftNavArrow} onClick={closeCropModal} />
            </NavIcon>
            <ModalTitle>Add Crop</ModalTitle>
          </ModalNavigation>

          <CropContainer>


            <CropCategory>Vegetables</CropCategory>
            <CropGrid>
              {crops.filter(crop => crop.category === 'Vegetables').map(crop => (
                <CropItemContainer>
                  <CropItem
                    key={crop.id}
                    selected={selectedCrop?.id === crop.id}
                    onClick={() => handleCropSelect(crop)}
                  >
                    <CropImage src={crop.image} alt={crop.name} />
                    <TickIcon src={tick_Icon} visible={selectedCrop?.id === crop.id} />

                  </CropItem>
                  <CropItemName>
                    {crop.name}
                  </CropItemName>
                </CropItemContainer>
              ))}
            </CropGrid>
          </CropContainer>

          <CropContainer>


            <CropCategory>Fruits</CropCategory>
            <CropGrid>
              {crops.filter(crop => crop.category === 'Fruits').map(crop => (
                <CropItemContainer>
                  <CropItem
                    key={crop.id}
                    selected={selectedCrop?.id === crop.id}
                    onClick={() => handleCropSelect(crop)}
                  >
                    <CropImage src={crop.image} alt={crop.name} />
                    <TickIcon src={tick_Icon} visible={selectedCrop?.id === crop.id} />

                  </CropItem>
                  <CropItemName>
                    {crop.name}
                  </CropItemName>
                </CropItemContainer>
              ))}
            </CropGrid>
          </CropContainer>


          <CropContainer>


            <CropCategory>Legumes</CropCategory>
            <CropGrid>
              {crops.filter(crop => crop.category === 'Legumes').map(crop => (
                <CropItemContainer>
                  <CropItem
                    key={crop.id}
                    selected={selectedCrop?.id === crop.id}
                    onClick={() => handleCropSelect(crop)}
                  >
                    <CropImage src={crop.image} alt={crop.name} />
                    <TickIcon src={tick_Icon} visible={selectedCrop?.id === crop.id} />

                  </CropItem>
                  <CropItemName>
                    {crop.name}
                  </CropItemName>
                </CropItemContainer>
              ))}
            </CropGrid>
          </CropContainer>
          <div style={{ height: 100 }}></div>
          <ModalButtonContainer>
            <ModalButton type="submit">Save & Continue</ModalButton>
          </ModalButtonContainer>
        </ModalContent>
      </Modal>

    </>
  );
};

export default MyFarm;
