import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoginImage from '../asset/images/Loginimage.svg';
import Tick from '../asset/images/tick.svg';
import { useTranslation } from 'react-i18next';
import InstallButton from '../screen/installButton/InstallButton';
import * as apiClient from "../common/apiClient";
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0.00) 100%);
  position: relative; /* Add this to position children absolutely */
`;

const ImageContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  input {
    width: 90%;
    height: 60px;
    border-radius: 8px;
    font-size: 17px;
    border: 2px solid #00B800;
    padding-left: 20px;
    text-align:left;
    background-color: transparent;
    font-family: Poppins, sans-serif;

    &::placeholder {
      color: rgba(26, 26, 26, 0.30);
      font-size: 19px;
      font-weight: 500;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    margin-left: 10px;
    width: 80px;
    height: 65px;
    border-radius: 8px;
    background: #00B800;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #45a049;
      cursor: pointer;
    }
  }
`;

const LanguageSwitcher = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 10px;
    border: none;
    border-radius: 8px;
    background-color: #f0f0f0;
    cursor: pointer;
    font-size: 11px;

    &:hover {
      background-color: #d0d0d0;
    }

    &.active {
      background-color: #00B800;
      color: white;
    }
  }
`;

const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    localStorage.clear();
  }, []); // E

  const handleLogin = async() => {
      
    if (!apiClient.validateMobileNumber(mobileNumber)) {
        apiClient.showErrorMessage("Please enter correct mobile Number")
        return
         
    }  
    await apiClient.signIn(mobileNumber).then((res) => {  
      if(res && res[0].result)
      {
        apiClient.showSuccessMessage(res[0].message)
        localStorage.setItem("mobileNumber",mobileNumber);
        navigate('/otp-verification');
      }
      else 
      {
        localStorage.setItem("mobileNumber","");
        apiClient.showErrorMessage(res[0].message)
      } 

    });
    
    
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <LoginContainer>
      
      
      <div>
      <LanguageSwitcher>        
      <InstallButton className={ 'active' } />
        <button onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'active' : ''}>
          English
        </button>
        <button onClick={() => changeLanguage('hi')} className={i18n.language === 'hi' ? 'active' : ''}>
          हिंदी
        </button>        
      </LanguageSwitcher>
      </div>
      <ImageContainer>
        <img src={LoginImage} height={300} alt="Login Illustration" />
        
      </ImageContainer>
      <LoginBox>
        <input
          type="tel"  
          placeholder={t('Enter your mobile number')}
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <button onClick={handleLogin}>
          <img src={Tick} alt="Tick" />
        </button>
      </LoginBox>
      
    </LoginContainer>
  );
};

export default LoginPage;
