import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StoreIcon from '../../asset/images/StoreIcon.svg';
import starIcon from '../../asset/images/star.svg';
import locationIcon from '../../asset/images/locationIcon.svg';
import callIcon from '../../asset/images/callIcon.svg';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #1A1A1A;
  font-family: Montserrat;
`;

const ServicesTitle = styled.h2`
  
  font-size: 20px;
  color: #000;
`;

const AddStoreButton = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  background: #00B800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: #00a300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background: #008f00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const StoreListContainer = styled.div``;

const Store = styled.div`
  box-shadow: 10px 9.6px 23.04px 10px #0000001A;
  width: 98%;
  height: auto;
  min-height: 160px;
  margin-top: 20px;
  gap: 0px;
  border-radius: 12px;
  padding: 5px;
  background:white;
`;

const StoreNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

const StoreName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
  padding-left: 12px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 25px;
`;

const StoreRatingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

const RatingIcon = styled.img`
  object-fit: cover;
  width: 6%;
`;

const Rating = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #979797;
  padding-left: 4px;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StoreAddress = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 70%;
  padding: 5px;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #474747;
  opacity: 0.8;
`;

const StoreMapContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding-left: 14px;
  margin-top: 13px;
`;

const StoreMapIcon = styled.div`
  width: 5%;
`;

const StoreMapLabel = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2px;
  padding-left: 5px;
  color: #9A9494;
  width: 32%;
`;

const StoreDistance = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2px;
  color: #9A9494;
  width: 20%;
`;

const StoreCall = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 45%;
  padding-right: 20px;
`;

const ProductStores = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 10000); // Simulate loading delay
  const handleCall = (phoneNumber) => {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Please enter a phone number.");
    }
};
const openGoogleMaps = (latitude, longitude) => {
  if (latitude && longitude) {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
  } else {
      alert('Latitude and longitude are required.');
  }
};
  return (
    <div >
      
      <StoreListContainer>
        {loading ? (
          <Store>
            <StoreNameContainer>
              <StoreName><Skeleton width={200} /></StoreName>
              <MoreIcon as={Skeleton} width={30} height={25} />
            </StoreNameContainer>
            <StoreRatingContainer>
              <RatingIcon as={Skeleton} width={20} height={20} />
              <Rating><Skeleton width={40} /></Rating>
            </StoreRatingContainer>
            <StoreAddress><Skeleton count={2} /></StoreAddress>
            <StoreMapContainer>
              <StoreMapIcon><Skeleton circle={true} height={22} width={22} /></StoreMapIcon>
              <StoreMapLabel><Skeleton width={80} /></StoreMapLabel>
              <StoreDistance><Skeleton width={50} /></StoreDistance>
              <StoreCall><Skeleton width={40} /></StoreCall>
            </StoreMapContainer>
          </Store>
        ) : (
          <Store>
            <StoreNameContainer>
              <StoreName>Sharma Agri shop</StoreName>
              <MoreIcon src={StoreIcon} />
            </StoreNameContainer>
            <StoreRatingContainer>
              <RatingIcon src={starIcon} />
              <Rating>4.2</Rating>
            </StoreRatingContainer>
            <StoreAddress>
              Name of the store, block A, street 7 Sector 18, Noida, UP
            </StoreAddress>
            <StoreMapContainer>
              <StoreMapIcon><img height={22} src={locationIcon} /></StoreMapIcon>
              <StoreMapLabel onClick={() => openGoogleMaps(28.6490624,77.0768896)}>
                Show on map  |
              </StoreMapLabel>
              <StoreDistance>02 km</StoreDistance>
              <StoreCall><img src={callIcon} onClick={() => handleCall('9266743743')} /></StoreCall>
            </StoreMapContainer>
          </Store>
        )}
      </StoreListContainer>
    </div>
  );
};

export default ProductStores;
