import React, { useState } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation'; 
import bookingConfirmedSpray from '../../../asset/images/bookingConfirmedSpray.svg';
import { useTranslation } from 'react-i18next';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #F0F8EF;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
`;

const BookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-family: Poppins;
font-size: 17px;
font-weight: 600;
line-height: 24px;
text-align: left;

`;

const Description = styled.p`
  color: #9A9494;
  font-size: 14px;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;  
  border-top: 1px solid #E0E0E0;
  font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;

const CancelButtonContainer = styled.div`

display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom:10px;
  border-bottom:1px solid #D9D9D9;
  

`;
const CancelButton = styled.button`
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 20px 20;
  font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;


const ExploreButton = styled.button`
  background-color: #00C853;
  color: white;
  font-size: 18px;
  padding: 15px;
  border-radius: 8px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border: none;
`;
const FinalAmount = styled.div`
font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 24px;
text-align: left;
`;
const FinalDate = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;
const BookingId = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
padding: 0px 20px; 

`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;
const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 20%; 
  min-height: 20%;
  width: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PopTitle = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Content = styled.p`
  font-size: 14px;
  margin: 20px 0;
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;

`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns all children to the right */
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-left: 54px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonCancel = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 24px;
text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;
const BookingConfirmed = (props) => {
  const { t, i18n } = useTranslation();
  
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleConfirm = () => {
    console.log('Booking Canceled');
    setIsOpen(false);
  };
  return (
    <Container>
      <div>
      <Navigation serviceName={""} title={"Booking Confirmed"} onCancel={props.onCancel} />
      

      


        <BookingInfo>
          <Image src={bookingConfirmedSpray} alt="Booking Image" />
          <Title>Booking confirmed for {props.serviceName}</Title>
          <Description>{t(`gaoON agent will assign shortly for the field spray.please be available on selected date and time`)}.</Description>
        </BookingInfo>
        <BookingId>Booking ID: SP24090001</BookingId>
        <Details> 
          <div>
            <FinalDate>27 July, 2024</FinalDate>
            <FinalDate>08:00 - 12:00 PM</FinalDate>
          </div>
          <div>
            <FinalAmount  >&#8377; 500</FinalAmount>
          </div>
        </Details>
        <CancelButtonContainer>
        <CancelButton onClick={()=>{setIsOpen(true)}} >Cancel booking</CancelButton>
        </CancelButtonContainer>
        
      </div>
      <ExploreButton onClick={props.onExploreMore}>Explore more</ExploreButton>
     {isOpen && <Backdrop>
        <PopupContainer>
        <PopupHeader>
          <PopTitle>Booking Cancel</PopTitle>
          <CloseButton onClick={handleClose}>&times;</CloseButton>
        </PopupHeader>
        <Content>Are you sure you want to cancel the booking?</Content>
        <ButtonGroup>
          <Button onClick={handleConfirm}>Yes</Button>
          <ButtonCancel onClick={handleClose}>No</ButtonCancel>
        </ButtonGroup>
        </PopupContainer>
      </Backdrop>}
    </Container>
  );
};

export default BookingConfirmed;
