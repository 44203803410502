
import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  width: 350px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: url('https://via.placeholder.com/350x200') no-repeat center center;
  background-size: cover;
`;

const BackButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const VideoIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  font-size: 14px;
  color: #666;
`;

const Note = styled.div`
  background-color: #e8f5e9;
  color: #388e3c;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
`;

const Price = styled.div`
  font-size: 18px;
  color: #000;
`;

const DiscountPrice = styled.span`
  font-size: 14px;
  color: #888;
  text-decoration: line-through;
  margin-left: 10px;
`;

const ScheduleButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
`;

const RequestService = () => {
  return (
    <Container>
      <Header>
        <BackButton>&larr;</BackButton>
        <VideoIcon>
          <span>&#9658;</span>
        </VideoIcon>
      </Header>
      <Content>
        <Title>Field Inspection</Title>
        <Description>
          Field inspection, that is, inspection of standing seed crops is an essential step in verifying conformity of seed crops to prescribed certification standards. Objective: To verify seed origin and identity of the variety.
        </Description>
        <List>
          <ListItem>To verify seed origin and identity of the variety.</ListItem>
          <ListItem>To collect information on cropping history of the seed field.</ListItem>
        </List>
        <Note>
          Please note: Customer should be available at the field as per selected time slot.
        </Note>
      </Content>
      <Footer>
        <Price>
          ₹300 <DiscountPrice>₹500</DiscountPrice>
        </Price>
        <ScheduleButton>Schedule</ScheduleButton>
      </Footer>
    </Container>
  );
};

export default RequestService;
