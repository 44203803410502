import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import weatherIcon from '../../asset/images/weathercloudy.svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton CSS for styling

// Main WeatherCard container
const WeatherCard = styled.div`
  height: 150px;
  margin-top: 20px;
  border-radius: 15px;
  background: #5F8BCC;
  box-shadow: 0px 4px 24px -1px #00000040;
  display: flex;
  padding: 10px 10px 10px 10px;
  color: white;
`;

// Left column for weather description and icon
const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start; /* Align children to the top */
`;

// Divider in the middle
const Divider = styled.div`
   width: 2px;
  background-color: white;
  margin: 0 20px;
  height: 130px;
  box-shadow: 0px 4px 4px 0px #00000040;

border: 2px ;

`;

// Right column for temperature, location, and day
const RightColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start; /* Align children to the top */
  align-items: flex-start;
`;

// Weather description at the top of the left column
const WeatherDescription = styled.div`
  font-size: 16px;
`;

// Weather icon at the bottom of the left column
const WeatherIcon = styled.img`
  margin-top: 20px;
  object-fit: cover;
`;

// Temperature display in the right column
const TempInfo = styled.div`
  font-size: 60px;
  font-weight: bold;
  text-align: left;
`;

// Location information at the top of the right column
const LocationInfo = styled.div`
  font-size: 16px;
  text-align: left;
`;

// Day information at the bottom of the right column
const DayInfo = styled.div`
  font-size: 16px;
  text-align: left;
`;

const Weather = () => {
  const [loading, setLoading] = useState(true);
  const [weather, setWeather] = useState(null);

  const API_KEY = '7bec0ea71bf2a733f4f06526f1501d8f';
  
  const fetchWeather = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&APPID=${API_KEY}&units=metric`
      );
      const json = await response.json();
      setWeather(json);
    } catch (error) {
      console.error("Error fetching weather data:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const getLocationAndFetchWeather = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            fetchWeather(latitude, longitude);
          },
          error => {
            console.error('Error getting location:', error);
            // Handle error, maybe set some default location or show an error message
            setLoading(false);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        // Handle the case where geolocation is not supported
        setLoading(false);
      }
    };

    getLocationAndFetchWeather();
  }, []);

  return (
    <WeatherCard>
      <LeftColumn>
        {loading ? (
          <Skeleton width={100} height={20} />
        ) : (
          <WeatherDescription>{weather?.weather[0]?.description || 'Partly Cloudy'}</WeatherDescription>
        )}
        {loading ? (
          <Skeleton circle={true} height={50} width={50} style={{ marginTop: '20px' }} />
        ) : (
          <WeatherIcon src={`http://openweathermap.org/img/w/${weather.weather[0].icon}.png` } alt="Weather Icon" />
          
        )}
      </LeftColumn>
      <Divider />
      <RightColumn>
        {loading ? (
          <>
            <Skeleton width={100} height={20} />
            <Skeleton width={60} height={50} />
            <Skeleton width={50} height={20} />
          </>
        ) : (
          <>
            <LocationInfo>{weather?.name || 'Unknown Location'}</LocationInfo>
            <TempInfo>{weather?.main?.temp ? `${Math.round(weather.main.temp)}°` : 'N/A'}</TempInfo>
            <DayInfo>{new Date().toLocaleDateString('en-US', { weekday: 'long' })}</DayInfo>
          </>
        )}
      </RightColumn>
    </WeatherCard>
  );
};

export default Weather;
