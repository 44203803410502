import React, { useState, useEffect } from 'react';
import {useNavigate  } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';
import styled from 'styled-components';
import Drawericon from '../asset/images/drawericon.svg';
import searchImg from '../asset/images/searchIcon.svg';
import { useTranslation } from 'react-i18next';
// Example images (replace with actual image imports)
import fieldInspection from '../asset/images/fieldInspection.jfif';
import fieldspray from '../asset/images/fieldspray.jfif';
import soilTesting from '../asset/images/soilTesting.jfif';
import addFarm from '../asset/images/addFarm.svg';
import MyFarm from './component/MyFarm';
import ProductCategory from './component/ProductCategory';
import Stores from './component/Stores';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import Weather from './component/Weather';
import Popup from './component/Popup';
import RequestService from './component/RequestService';
import Service from './component/services/Service';





// Styled components for layout and design
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;  
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%);
  padding: 20px;
  overflow: hidden; 
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const DrawerIconImg = styled.img`
  cursor: pointer;
  margin-right: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  color: #1A1A1A;
  font-family: Montserrat;
 
`;

const Subtitle = styled.span`
  font-size: 14px;
  color: #979797;
  font-family: Montserrat;
 

`;


const SearchBar = styled.div`
  width: 90%;
  padding-left:20px;
  padding-right:20px;
  height:50px;
  margin-top: 15px;
  background: rgba(255, 255, 255, 1);
  border: 0px solid #979797;
  display:flex,
  box-shadow: 0px 3.84px 7.68px 0px #0000000F;
  border-radius:10px;
  flex-direction: row;
  display:flex;

`;




const ServicesTitle = styled.div` 
  
  color: #000;
  font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;

`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden; // Ensure the image does not overflow the container
  margin-top: 10px;
  border-radius: 5px;
`;

// Styled component for the image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Cover the container while maintaining aspect ratio
  

`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0; /* Align to the left edge of the container */
  width: 100%; /* Make sure it spans the full width of the container */
  height: 100%; /* Make sure it spans the full height of the container */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  z-index: 1; /* Ensure it is above the image but below the text */
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 8px; /* Slightly smaller border radius on smaller screens */
  }

  @media (max-width: 480px) {
    border-radius: 5px; /* Even smaller border radius on very small screens */
  }
`;
// Styled component for the text overlay
const TextOverlay = styled.div`
  position: absolute;
  bottom: 1%; /* Align to the bottom with some spacing */
  left: 0; /* Align to the left */
  width: 100%; /* Full width of the parent container */
  padding: 10px; /* Add padding for better readability */
  color: white; /* Text color */
  font-size: 20px; /* Font size */
  font-weight: bold; /* Font weight */
  text-align: left; /* Align text to the left */
  z-index: 2; /* Ensure it's above the image and gradient overlay */
  font-family: Poppins; /* Font family */
 
  border-radius: 5px; /* Optional: rounded corners */
`;
const LocationButton = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  user-select: none;
  background: #00B800;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: #00a300;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    background: #008f00;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;


const LocationButtonTitle = styled.div`
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Poppins;
  height: 100%;
  font-size: 19px;
  font-weight: 600;
  text-align: left; 
  color: #FFFFFF; 
  padding: 0 10px; 
`;

const ImageWithSkeleton = ({ src, alt, text }) => {
  const [loaded, setLoaded] = useState(false);


  return (
    <ImageContainer>
      {!loaded && <Skeleton height="200px" width="100%" />}
      <Image
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        loaded={loaded}
      />
      {loaded && <GradientOverlay />}
      <TextOverlay>{text}</TextOverlay>
    </ImageContainer>
  );
};





const HomeScreen = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [locationName, setLocationName] = useState('....');
  const [shortName, setShortName] = useState('');
  const [locationError, setLocationError] = useState('');
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [selectedService, setSelectedService] = useState(null);
  const [openServiceRequest, setServiceRequest] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [permissionPrompt, setPermissionPrompt] = useState(false); // State to show the prompt

  const navigate = useNavigate();
  useEffect(() => {       
    let token= localStorage.getItem("token");  
      if(token!=null && token.length>0 )
      {
        //navigate('/home');
      }
      else
      {
        navigate('/login');
      }
      
    
  }, [navigate]);

  useEffect(() => {
    const handleBackNavigation = (event) => {
      event.preventDefault(); // Prevents default behavior of back button
      window.history.pushState(null, null, window.location.href); // Keep pushing the current state to prevent back navigation
    };

    // Add a new history state to block back navigation
    window.history.pushState(null, null, window.location.href);

    // Attach the popstate event listener
    window.addEventListener('popstate', handleBackNavigation);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [navigate]);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });

          setPermissionPrompt(false); // Hide the prompt after permission is granted
        },
        (error) => {
          alert(error.message);
          //  setPermissionPrompt(false); // Hide the prompt if there's an error
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by this browser.');
      setPermissionPrompt(false); // Hide the prompt if geolocation is not supported
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchLocationName(latitude, longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(t('Please enable location services to use this feature.'));
              break;
            case error.POSITION_UNAVAILABLE:
              alert(t('Location information is unavailable.'));
              break;
            case error.TIMEOUT:
              alert(t('The request to get user location timed out.'));
              break;
            case error.UNKNOWN_ERROR:
              alert(t('An unknown error occurred.'));
              break;
            default:
              alert(t('Unable to fetch location.'));
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setLocationError(t('Geolocation is not supported by this browser.'));
    }
  }, [t]);

  const fetchLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBlnioXQ9Ca7BoopzZgM4BVNg43e9RWrVk`, {
      });


      const addressComponents = response.data.results[0].address_components;
      let city = '';
      let town = '';

      addressComponents.forEach(component => {
        if (component.types.includes('locality')) {
          setLocationName(component.long_name);  // Get city
          
        }
        if (component.types.includes('administrative_area_level_3')) {
          setShortName(component.long_name)  // Get town
          localStorage.setItem("sublocation",component.long_name);
        }
      });
      // setLocationName(location.components.city || location.components.town || 'Unknown Location');
      // setShortName(`${location.components.suburb || location.components.neighbourhood || ''}, ${location.components.city || location.components.town || 'Unknown'}`);
    } catch (error) {
      console.error("Error fetching location name:", error);
      setLocationName('Unable to fetch location');
    }
  };

  return (
    <>
      <div>
        {isDrawerOpen && <DrawerMenu isOpen={isDrawerOpen} onClose={toggleDrawer} />}
        <HomeContainer onClick={() => isDrawerOpen && toggleDrawer()}>
          {/* Header */}
          <Header>
            <DrawerIconImg src={Drawericon} onClick={toggleDrawer} />
            <TitleContainer>
              <Title>{locationName}</Title>
              <Subtitle>{shortName}</Subtitle>
            </TitleContainer>
          </Header>

          {/* Search Bar */}
          {/* <SearchBar >
          <SearchInput 
          placeholder={t('Search products')}
          
          />
          <SearchIcon >
            <img src={searchImg}/>
            </SearchIcon>
          </SearchBar> */}

          {/* Services Section */}
          <ServicesTitle>
            <TitleContainer>
              <ServicesTitle>{t('Services')}</ServicesTitle>
            </TitleContainer>

          </ServicesTitle>
          {/* <ImageWithSkeleton
            src={fieldInspection}
            alt={t('Field Inspection')}
            text={t('Field Inspection')}
          /> */}
          <Service cardImage={fieldInspection} serviceName={"Field Inspection"} serviceId={1} price={"₹ 300"}mrp={"₹ 500"} videoLink={"https://www.youtube.com/embed/RRrtIY-9i7c?autoplay=1"}/>
          <Service cardImage={fieldspray} serviceName={"Field Spray"} serviceId={2}price={"₹ 100/Tank"} mrp={""} videoLink={"https://www.youtube.com/embed/f5340jf4BAM?autoplay=1"}/>
          <Service cardImage={soilTesting} serviceName={"Soil Testing"} serviceId={3} price={"₹ 300"} mrp={"₹ 500"} videoLink={"https://www.youtube.com/embed/l8jYEQNPl-k?autoplay=1"}/>
 
          <MyFarm showAdd={true} />
          <ProductCategory />
          <div style={{ textAlign: 'center', padding: '20px' }}>
            {permissionPrompt && (
              <LocationButton onClick={requestLocation}>
                <LocationButtonTitle>Enable Location</LocationButtonTitle>
              </LocationButton>
            )}
            {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
          </div>

          {!permissionPrompt && <Stores />}
          {!permissionPrompt && <Weather />}
          
        </HomeContainer>

      </div>

    </>
  );
};

export default HomeScreen;
