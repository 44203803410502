import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import SplashScreen from './screen/SplashScreen';
import LoginPage from './screen/LoginPage';
import OtpVerificationPage from './screen/OtpVerificationPage';
import HomeScreen from './screen/Home';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import 'react-loading-skeleton/dist/skeleton.css';
import { isMobile } from 'react-device-detect';
 

const App = () => {
  
  return (
    <Router>
        <ToastContainer
          position="bottom-center" // Position toast notifications at the bottom center
          autoClose={5000} // Auto close after 5 seconds
          hideProgressBar={false} // Show progress bar
          newestOnTop={false} // Show the newest toast at the bottom
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      {isMobile ? (
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/otp-verification" element={<OtpVerificationPage />} />
          <Route path="/home" element={<HomeScreen />} />
        </Routes>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h2>This app is only available on mobile devices.</h2>
          <p>Please access this application using your mobile device.</p>
        </div>
      )}
    </Router>
  );
};

export default App;
