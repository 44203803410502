// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { register as registerServiceWorker } from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Register the service worker
registerServiceWorker({
  onSuccess: (registration) => {
    console.log('Service worker registered successfully:', registration);
  },
  onUpdate: (registration) => {
    console.log('Service worker updated:', registration);
  }
});
