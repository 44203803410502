import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import splashbg from '../asset/images/splashbg.svg'; 
import LogoImg from '../asset/images/Logo.svg';
const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(20%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const SplashContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
background-image: 
    linear-gradient(rgba(0, 255, 0, 0.10), rgba(0, 255, 0, 0.10)),    
    url(${splashbg});
  background-repeat: repeat;
`;

const Logo = styled.img`
  width: 50%;
  height: auto;
  animation: ${slideIn} 1s ease-out;
  opicity:0.7;
  @media (min-width: 768px) {
    width: 30%;
  }
  @media (min-width: 1024px) {
    width: 20%;
  }
`;

const BottomDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  
  text-align: center;
  padding: 10px;
  font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 14.07px;
letter-spacing: -0.19236843287944794px;
text-align: center;
color:#D8D8D8;
`;
const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      let token= localStorage.getItem("token"); 
      if(token!=null && token.length>0 )
      {
        navigate('/home');
      }
      else
      {
        navigate('/login');
      }
      
    }, 3000);
  }, [navigate]);

  return (
    <SplashContainer >
      <Logo src={LogoImg} alt="Logo" />
      <BottomDiv>
      Vertical Agroscience Pvt. Ltd.
      </BottomDiv>
    </SplashContainer>
  );
};

export default SplashScreen;
