import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as http from "./http";

const getApi = () => {
  let hostname = window.location.host;
  var arrValue = hostname.split(".");
  var result = arrValue[0];
  if (result.includes("localhost") === true) {
    result = "http://localhost:8499/";
  } else {
    result = "";
  }
 
  
  return result;
};

export const APIUrl = getApi();

 export const showErrorMessage = (message) => {
    toast.error(message, {
        
         theme: "colored" 
    }); 
};
export const showSuccessMessage = (message) => {
  toast.success(message, {
      
       theme: "colored" 
  }); 
};
export const validateEmail = (email) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
export const post = async(resource,payload,header) => { 
  let api = APIUrl + `${resource}`;
  return http.post(api, payload).then((response) => {
    return response;
  });
}
export const get = async(resource,header) => { 
  let api = APIUrl + `${resource}`;
  return http.get(api).then((response) => {
    return response;
  });
}

export const formatDate = (date) => {
  if(date==null)
  {
    return "NA";
  }
  const originalTimestamp = date;
  return new Date(originalTimestamp).toLocaleString('en-US', { timeZone: 'UTC' });
};

export const validateMobileNumber = (number) => {
  // Regular expression to check if the number has exactly 10 digits
  const regex = /^\d{10}$/;
  return regex.test(number);
};
export const signIn = (mobileNo) => {
  let api = APIUrl + "/getOTP/"+mobileNo;
  return http.get(api).then(response => {
    return response;
});
}

export const verifyOtp = (mobileNo,otp) => {
  let api = APIUrl + "/verifyOTP/"+mobileNo+"/"+otp;
  return http.get(api).then(response => {
    return response;
});
}







