import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import leftNavArrow from '../../../asset/images/leftNavArrow.svg';
import { useTranslation } from 'react-i18next';
const Navigation = (props) => {
   
  const { t, i18n } = useTranslation();
 

  return (
     
         <ModalNavigation>
          <NavIcon >
            <img src={leftNavArrow} onClick={props.onCancel}  />
            </NavIcon>
            <ModalTitleConatiner>
          <ModalTitle>{t(props.title)}</ModalTitle>
          <ServiceTitle>{props.serviceName}</ServiceTitle>
          </ModalTitleConatiner>
          </ModalNavigation>
      
  );
};

export default Navigation;

const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;
  
   

`;
const NavIcon = styled.div`
padding-top:20px

`;
const ModalTitleConatiner = styled.h2`
display:flex; 
flex-direction:column; 
padding-left:20px
`;
const ModalTitle = styled.div`
display:flex; 
font-family: Poppins;
color: #1A1A1A; 
font-size: 25.24px;
font-weight: bolder; 
line-height: 23.09px;
text-align: left;

`;

const ServiceTitle = styled.div`
display:flex; 
color: rgba(154, 148, 148, 1);
font-family: Poppins;
font-size: 16px;
font-weight: 400;
padding-top:5px; 
text-align: left;


`;
