import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check if the prompt was dismissed before and show the button again
    const dismissed = localStorage.getItem('installPromptDismissed');
    if (dismissed === 'true') {
      setIsVisible(true);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    const choiceResult = await deferredPrompt.userChoice;

    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the install prompt');
      localStorage.removeItem('installPromptDismissed'); // Reset if accepted
    } else {
      console.log('User dismissed the install prompt');
      localStorage.setItem('installPromptDismissed', 'true');
    }

    setDeferredPrompt(null);
    setIsVisible(false);
  };

  return (
    isVisible && (
      <button id="installButton" onClick={handleInstallClick}>
        {t('Install App')}
      </button>
    )
  );
};

export default InstallButton;
