import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import fieldInspection from '../../../asset/images/fieldInspection.jfif';
import playbutton from '../../../asset/images/playbutton.svg';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal'; // React Modal library
import leftNavArrow from '../../../asset/images/leftNavArrow.svg';
import whiteRightArrow from '../../../asset/images/white-right-arrow.svg';
import DateSelection from './DateSelection';
import Payment from './Payment';
import BookingConfirmed from './BookingConfirmed';
Modal.setAppElement('#root'); // Set the app root element for accessibility

const Container = styled.div`
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.01) 0%, rgba(71, 166, 72, 0) 100%);

  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
  height:100%
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 0, 0, 0.0);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  margin-bottom: 10%;
  overflow-y: auto; /* Enable vertical scrolling */

  /* Default max-height */
  max-height: 490px;

  /* Adjust max-height for different screen sizes */
  @media (max-width: 1200px) {
    max-height: 450px;
  }

  @media (max-width: 992px) {
    max-height: 400px;
  }

  @media (max-width: 768px) {
    max-height: 350px;
  }

  @media (max-width: 576px) {
    max-height: 500px;
  }
    
`;


const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0;
  color: #000;
  
`;
const fadeAnimation = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
`;

// Update the Description component with animation
const Description = styled.p`
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 1);
  margin: 10px 0;
  font-family: Poppins;
  font-weight: 200;
  text-align: left;
  line-height: 1.6;
  letter-spacing: 0.5px;
  
  animation: ${fadeAnimation} 1.5s ; /* Apply the animation */
`;

const List = styled.ul`
  padding-left: 20px;
  font-size: 0.9rem;
  color: #555;
  margin: 10px 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  font-family: Poppins;
  font-size: 1.1rem;
text-align: left;
line-height: 1.6; /* Increase line height (adjust as needed) */
  letter-spacing: 0.5px; /* Increase text spacing (adjust as needed) */
  color:black;

`;
const NoteContainer = styled.div`
background: rgba(0, 184, 0, 0.1);
padding: 15px;
border-radius: 8px;
  margin: 15px 0;
  color: #333;
`
const Note = styled.div`
  

  font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 18px;
text-align: left;
color: rgba(154, 148, 148, 1);

  
  font-size: 0.9rem;
  
`;
const NoteTitle = styled.div`
  font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;

  
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow to distinguish it from the content */
  z-index: 1000; /* Optional: ensures it's above other content */
`;


const Price = styled.div`
  font-size: 1.2rem;
  color: #000;
  span {
    text-decoration: line-through;
    color: #888;
    font-size: 0.9rem;
    margin-left: 5px;
  }
`;

const Button = styled.button`
  background-color: #00c853;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-right: 30px;
  cursor: pointer;
  font-size: 1rem;
`;

const ServiceImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden; // Ensure the image does not overflow the container
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

// Styled component for the image
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; // Cover the container while maintaining aspect ratio
`;

const TextOverlay = styled.div`
  position: absolute;
  bottom: 1%; /* Align to the bottom with some spacing */
  left: 0; /* Align to the left */
  width: 100%; /* Full width of the parent container */
  padding: 10px; /* Add padding for better readability */
  color: white; /* Text color */
  font-size: 20px; /* Font size */
  font-weight: bold; /* Font weight */
  text-align: left; /* Align text to the left */
  z-index: 2; /* Ensure it's above the image and gradient overlay */
  font-family: Poppins; /* Font family */
  border-radius: 5px; /* Optional: rounded corners */
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0; /* Align to the left edge of the container */
  width: 100%; /* Make sure it spans the full width of the container */
  height: 100%; /* Make sure it spans the full height of the container */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  z-index: 1; /* Ensure it is above the image but below the text */
  border-radius: 10px;

  @media (max-width: 768px) {
    border-radius: 8px; /* Slightly smaller border radius on smaller screens */
  }

  @media (max-width: 480px) {
    border-radius: 5px; /* Even smaller border radius on very small screens */
  }
`;

const ModalContent = styled.div`
  padding: 0px;
  background: #F8FAF5;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const NavIcon = styled.div`

position: absolute;
  top: 20px;
  left: 20px;
  z-index:1 ;


`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const ImageWithSkeleton = ({ src, alt, text, open }) => {
  const [loaded, setLoaded] = useState(false);



  return (
    <ServiceImageContainer onClick={open}>
      {!loaded && <Skeleton height="200px" width="100%" />}
      <Image
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        loaded={loaded}
      />
      {loaded && <GradientOverlay />}
      <TextOverlay>{text}</TextOverlay>
    </ServiceImageContainer>
  );
};

const Service = (props) => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startVideo, setStartVideo] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height:230px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(253, 254, 253, 0.9) 100%, #F9FCF8 0%),url(${props.cardImage}) no-repeat center center ;
  background-size: cover;
 
  

  &::after {
    content: '';
    position: absolute;
    bottom: 10;
    left: 0;
    right: 0;
    height: 1px; /* Blur only the bottom 10px */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(253, 254, 253, 0.9) 100%); /* Matches the gradient background */
    filter: blur(1px); /* Applies the blur effect */
    z-index: 1; /* Makes sure it's layered on top */
    
  }

  

`;

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleDateChange = (date) => {

    setSelectedDate(date);
    setIsScheduling(false);
    setIsPayment(true);
  }

  return (
    <>
      <ImageWithSkeleton
        open={openModal}
        src={props.cardImage}
        alt={t(props.serviceName)}
        text={t(props.serviceName)}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Farm"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          {!isScheduling && !isPayment && !isBookingConfirmed &&  <Container>
            <NavIcon >
              {!startVideo && <img src={leftNavArrow} onClick={closeModal} />}
              {startVideo && <img src={whiteRightArrow} onClick={() => setStartVideo(false)} />}

            </NavIcon>

            {startVideo && <Iframe
              src={props.videoLink}
              title="YouTube video"
              allow="autoplay; fullscreen"
            />
            }
            {!startVideo &&
              <>
                <ImageContainer>

                  <PlayButton>
                    <img src={playbutton} onClick={() => setStartVideo(true)} alt="Play" />
                  </PlayButton>

                </ImageContainer>
                <Content>
                  <Title>{t(props.serviceName)}</Title>
                  <Description>
                    Field inspection, that is, inspection of standing seed crops is an essential step in verifying conformity of seed crops to prescribed certification standards. Objective. To verify seed origin and identity of the variety.
                  </Description>

                  <List>
                    <ListItem>The field inspections are done to achieve the following objectives: 1. To verify seed origin (source seed) and identity of the variety. 2. To collect information on cropping history of the seed field, i.e. to verify whether the seed field meets the prescribed land requirements.</ListItem>

                  </List>
                  <NoteContainer>
                    <NoteTitle>Please note</NoteTitle>
                    <Note>Customer should be available at the field as per selected time slot</Note>
                  </NoteContainer>

                </Content>
                <Footer>
                  <Price>{props.price} <span>{props.mrp}</span></Price>
                  <Button onClick={() => { setIsScheduling(true) }} >{t('Schedule')}</Button>
                </Footer>
              </>}
          </Container>}
          {isScheduling && <Container>
            <DateSelection defaultDate={selectedDate} onDateChange={handleDateChange} serviceName={t(props.serviceName)} select onCancel={() => { setIsScheduling(false) }} />
          </Container>}
          {isPayment && <Container>
            <Payment defaultDate={selectedDate} OnBookingConfiremed={()=>{setIsBookingConfirmed(true); setIsPayment(false)}}  serviceName={t(props.serviceName)} select onCancel={() => { setIsPayment(false);setIsScheduling(true)  }} />
          </Container>}
          {isBookingConfirmed && <Container>
            <BookingConfirmed  serviceName={t(props.serviceName)} onExploreMore={()=>{ setIsBookingConfirmed(false); setIsPayment(false);setIsScheduling(false);setIsModalOpen(false); } } onCancel={() => { setIsPayment(false);setIsScheduling(false);setIsModalOpen(false);  }} />
          </Container>}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Service;
