import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import leftNavArrow from '../asset/images/leftNavArrow.svg';
import lineSp from '../asset/images/lineSp.svg';
import editPencil from '../asset/images/editPencil.svg';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom'; // import the useNavigate hook

const MenuContainer = styled(Menu)`
  background-color: #E9F4E9;
  padding-top: 20px;
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 95% !important;
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.5) !important;
    transition: opacity 0.3s ease;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
    
  }

  .bm-cross {
    background: #333;
  }

  .bm-burger-bars {
    background: #333;
  }
`;




const UserInfo = styled.div`
  padding: 20px;
  background-color: #E9F4E9;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  border-bottom: 0px dotted #e0e0e0;
  width:90%;
  
  
`;
const NavContainer = styled.div`
  height:10%;
  width:100%;
  display:flex !important;
  flex-direction:row;
`;
const NavIcon = styled.div`
  padding: 20px; 
  width:10%;
  
`;


const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // This will add spacing between the name and the edit icon
`;

const EditIcon = styled.div`
  cursor: pointer;
  width:10%;
`;
const UserName = styled.h2`
  margin: 0;
  color: #1A1A1A;
  width:90%;
  font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;

  text-align:left !important;
`;

const UserLocation = styled.p`
  margin: 0;
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
  color: #1A1A1A;
text-align: left;

  text-align:left;
`;

const SectionTitle = styled.h3`
  padding: 10px 20px;
  font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 0px;
text-align: left;
color: #1A1A1A;

`;

const OrderList = styled.div`
  padding: 10px 20px;
`;

const OrderItem = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const OrderStatus = styled.div`
font-family: Poppins;
font-size: 12px;
font-weight: 500; 
letter-spacing: 1px;
text-align: left;

  color: ${props => props.status === 'PENDING' ? '#00B800' : '#9A9494'};
  margin-bottom: 5px;
`;


const OrderedItem = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 600;
color:#1A1A1A;
text-align: left; 
`;
const OrderDetails = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #9A9494;

`;


const FarmListContainer = styled.div`
padding-left: 20px;
padding-right: 20px;
`;

const Farm = styled.div`
  box-shadow: 10px 9.6px 23.04px 10px #0000001A;
  width: 98%;
  height: auto;
  min-height: 120px;
  margin-top: 20px;
  gap: 0px;
  border-radius: 12px;
   
  background:white;
`;

const FarmNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  
  
`;

const FarmName = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  font-family: Poppins;
  height: auto;
  min-height: 10px;
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
`;

const MoreIcon = styled.img`
  width: 10%;
  height: 20px;  
  padding-top: 2px;
`;

const FormDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  
  padding-left: 20px;
  color: #474747;
`;
const LanguageSection = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
 

`;

const LanguageButton = styled.button`
  width: 45%;
  padding: 10px;
  background: ${props => props.active ? '#4CAF50' : '#D0E8D0'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  &:hover {
    background: ${props => props.active ? '#45A049' : '#D0E8D0'};
  }
`;


const LogoutButton = styled.button`
  width: 90%; 
  border-radius: 8px;
  border: 0.96px solid #9A9494;
  background-color: #FFFFFF; /* Ensure background color is set */
  color: #1A1A1A;
  margin-top: 20px; /* Add some margin to ensure it’s not too close to other elements */
  margin-bottom: 20px; /* Adjust bottom margin if needed */
  height: 50px;
  display: block; /* Ensure the button is a block element */
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-bottom:30px;
  margin-left:15px;
  margin-right:10px;
  &:hover {
    background-color: #8A8A8A; /* Slightly darker on hover for better UX */
  }
`;

const LinkButton = styled.a`
  font-family: Montserrat;
font-size: 16px;
font-weight: 500;
height:30px;
width:100%;
text-align: left;
margin-bottom:10px;
color: #9A9494;
  text-decoration: underline;
padding-left:15px;

 
`;


const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;
const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 20%; 
  min-height: 20%;
  width: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

`;
 

const InputLabel = styled.label`
  font-size: 16px;
  margin-bottom: 10px; 
  font-weight: bold;
  align-self: flex-start;
`;

const TextInput = styled.input`
  width: 100%;
  padding-top: 40px;
  font-size: 20px;
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns all children to the right */
  width: 100%;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
  
  &:first-child {
    margin-right: 20px;
  }
`;
const DrawerMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isEditName, setIsEditName] = useState(false);
  const navigate = useNavigate(); // Initialize the hook

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handleArrowClick = () => {
    props.onClose();
  };
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simulate loading delay
  }, []);
  const handlePolicy = () => {
    window.open("http://www.vagroscience.com/PrivacyPolicy.html", "_blank");
  };
  return (
    <MenuContainer>
      <NavContainer>
        <NavIcon onClick={handleArrowClick}>
          <img src={leftNavArrow} alt="Close Menu" />
        </NavIcon>
        <UserInfo>
          <UserNameContainer>
            <UserName>Guest</UserName>
            <EditIcon> <img src={editPencil} onClick={()=>{setIsEditName(true)}} /></EditIcon>
          </UserNameContainer>
          <UserLocation>{localStorage.getItem("sublocation")}</UserLocation>
        </UserInfo>
      </NavContainer>

      <img src={lineSp} style={{ paddingLeft: 10, paddingRight: 10 }} alt="Close Menu" />
      <SectionTitle>{t('My Orders')}</SectionTitle>
      <OrderList>
        <OrderItem>
          <OrderStatus status="PENDING">{t('PENDING')}</OrderStatus>
          <OrderedItem>Inspection</OrderedItem>
          <OrderDetails>27 Jul 2024, 12:00 - 02:00 PM</OrderDetails>
        </OrderItem>
        <OrderItem>
          <OrderStatus status="COMPLETED">{t('COMPLETED')}</OrderStatus>
          <OrderedItem>Soil Testing</OrderedItem>
          <OrderDetails>5 Jan 2024, 12:00 - 02:00 PM</OrderDetails>
        </OrderItem>
        <OrderItem>
          <OrderStatus status="COMPLETED">{t('COMPLETED')}</OrderStatus>
          <OrderedItem>Field Spray</OrderedItem>
          <OrderDetails>2 Jan 2024, 12:00 - 02:00 PM</OrderDetails>
        </OrderItem>
      </OrderList>

      <img src={lineSp} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }} alt="Close Menu" />
      <SectionTitle>{t('Farm History')}</SectionTitle>

      <FarmListContainer>
        {loading ? (
          <>
            <Skeleton height={120} style={{ marginBottom: 20, borderRadius: 12 }} />
            <Skeleton height={120} style={{ marginBottom: 20, borderRadius: 12 }} />
          </>
        ) : (
          <>
            <Farm>
              <FarmNameContainer>
                <FarmName>Khet 1</FarmName>

              </FarmNameContainer>
              <FormDetail>
                {t('Current age')}: <span style={{ marginLeft: 5 }}><b>20 - 30 Days</b></span>
              </FormDetail>
              <FormDetail>
                {t('Current crop')}: <span style={{ marginLeft: 5 }}><b>Paddy</b></span>
              </FormDetail>
              <FormDetail>
                {t('Farm area')}: <span style={{ marginLeft: 5 }}><b>6 Bigha</b></span>
              </FormDetail>
            </Farm>

            <Farm>
              <FarmNameContainer>
                <FarmName>Khet 2</FarmName>

              </FarmNameContainer>
              <FormDetail>
                {t('Current age')}: <span style={{ marginLeft: 5 }}><b>20 - 30 Days</b></span>
              </FormDetail>
              <FormDetail>
                {t('Current crop')}: <span style={{ marginLeft: 5 }}><b>Paddy</b></span>
              </FormDetail>
              <FormDetail>
                {t('Farm area')}: <span style={{ marginLeft: 5 }}><b>6 Bigha</b></span>
              </FormDetail>
            </Farm>
          </>
        )}
      </FarmListContainer>
      <img src={lineSp} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }} alt="Close Menu" />
      <SectionTitle>{t('Select App Language')}</SectionTitle>
      <LanguageSection>
        <LanguageButton active={i18n.language === 'en'} onClick={() => changeLanguage('en')}  >English</LanguageButton>
        <LanguageButton active={i18n.language === 'hi'} onClick={() => changeLanguage('hi')} >हिंदी</LanguageButton>
      </LanguageSection>
      <LinkButton onClick={handlePolicy} >{t('Terms of use')}</LinkButton>
      <LinkButton onClick={handlePolicy}>{t('Privacy policy')}</LinkButton>

      <LogoutButton onClick={() => { navigate('/login') }}>{t('Logout')}</LogoutButton>
      <div style={{ minHeight: 1 }}></div>
      {isEditName && <Backdrop>
        <PopupContainer>  
        <InputLabel htmlFor="name">Enter Name</InputLabel>
        <TextInput type="text" id="name" />
      <ButtonContainer>
        <Button onClick={()=>{setIsEditName(false)}}>Cancel</Button>
        <Button onClick={()=>{setIsEditName(false)}}>Submit</Button>
      </ButtonContainer>
        </PopupContainer>
      </Backdrop>}
    </MenuContainer>
  );
};

export default DrawerMenu;
