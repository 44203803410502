import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import leftNavArrow from '../../../asset/images/leftNavArrow.svg';
import { useTranslation } from 'react-i18next';
import Navigation from './Navigation';
import MyFarm from '../MyFarm';
const App = (props) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState(props.defaultDate);
  const [dates, setDates] = useState([]);
  const { t, i18n } = useTranslation();

  // Generate the list of the current month, next month, and the month after
  const generateSelectableMonths = () => {
    const currentMonthIndex = new Date().getMonth();
    const months = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date();
      date.setMonth(currentMonthIndex + i);
      months.push({
        name: date.toLocaleString('default', { month: 'long' }),
        index: date.getMonth(),
        year: date.getFullYear(),
      });
    }
    return months;
  };

  const months = generateSelectableMonths();

  // Function to generate the dates for the selected month, starting from today if it's the current month
  const generateDatesForMonth = (month, year) => {
    const today = new Date();
    const startDate = (month === today.getMonth() && year === today.getFullYear()) ? today.getDate() + 1 : 1;
    const date = new Date(year, month, startDate);
    const days = [];
    while (date.getMonth() === month) {
      days.push({
        day: date.toLocaleDateString('en-US', { weekday: 'long' }),
        date: date.getDate(),
        time: `08:00${t('AM')} - 05:00${t('PM')}`, // Default time
      });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  useEffect(() => {
    const selectedYear = months.find((m) => m.index === selectedMonth)?.year;
    setDates(generateDatesForMonth(selectedMonth, selectedYear));
  }, [selectedMonth]);

  const handleMonthChange = (index) => {
    setSelectedMonth(index);
    setSelectedDate(null);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  return (
    <AppContainer>
      {/* <ModalNavigation>
          <NavIcon >
            <img src={leftNavArrow} onClick={props.onCancel}  />
            </NavIcon>
            <ModalTitleConatiner>
          <ModalTitle>{t('Select date & time')}</ModalTitle>
          <ServiceTitle>{props.serviceName}</ServiceTitle>
          </ModalTitleConatiner>
          </ModalNavigation> */}
      <Navigation serviceName={props.serviceName} title={"Select date & time"} onCancel={props.onCancel} />

      <Header>
        <MonthContainer>
          {months.map(({ name, index }) => (
            <MonthButton
              key={`${name}-${index}`}
              active={index === selectedMonth}
              onClick={() => handleMonthChange(index)}
            >
              {t(name)}
              {index === selectedMonth && <ArrowIndicator />}
            </MonthButton>
          ))}
        </MonthContainer>
      </Header>
      <SelectDate>{t('Select Date')}</SelectDate>
      <DateContainer>

        {dates.map((item) => (
          <DateItem
            key={item.date}
            selected={selectedDate === item.date}
            onClick={() => handleDateSelect(item.date)}
          >
            <DayContainer  >
              <Day>{t(item.day)}</Day>
              <DateNumber>{item.date}</DateNumber>
            </DayContainer>

            <Time>{item.time}</Time>
          </DateItem>
        ))}
      </DateContainer>

      {selectedDate && <BookButton onClick={() => props.onDateChange(selectedDate)} >{t('Book Now')}</BookButton>}
      {/* <Backdrop >
        <PopupContainer onClick={(e) => e.stopPropagation()}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TitleContainer>
              Select your farm
            </TitleContainer>
            <CloseButton onClick={() => {
              // setIsModalOpen(false);
              // setIsProductOpen(false);
              // setActiveCategory('All');
              // setStartVideo(false);
              // setIsConfirm(false);
            }} >×</CloseButton>
          </div>
          
            <FarmContainer>
              <MyFarm showAdd={false} />
            </FarmContainer>
          
        </PopupContainer>
      </Backdrop> */}
    </AppContainer>
  );
};

export default App;

// Styled Components

const AppContainer = styled.div`
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.01) 0%, rgba(71, 166, 72, 0) 100%);
`;

const Header = styled.header`
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
`;

const MonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
`;

const MonthButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) => (props.active ? '#000' : '#888')};
  opacity: ${(props) => props.active ? '1' : '0.5'};

  position: relative;
  transition: color 0.3s;
  min-width:100px;
  

  ${(props) =>
    props.active &&
    `
    color: #000;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #000;
      bottom: -8px;
      left: 0;
    }
  `}

  
  ${(props) =>
    !props.active &&
    `
    color: #000;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: lightgrey;
      bottom: -8px;
      left: 0;
      opicity:0.2;
    }
  `}
`;

const ArrowIndicator = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
`;

const DateContainer = styled.div`
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  overflow-x:hidden;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const DateItem = styled.div`
  background-color: ${(props) => (props.selected ? '#e6f9e8' : '#ffffff')}; /* Light green background for selected */
  border: ${(props) => (props.selected ? '2px solid #32a852' : '1px solid #ddd')}; /* Green border for selected */
  border-radius: 12px;
  padding: 16px 25px;
  margin-bottom: 15px;
  width: 89%;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  display:flex;
  flex-direction:row;
`;

const DayContainer = styled.div`
  
  border:none
   
  display:flex;
  flex-direction:column;
`;

const Day = styled.span`
  display: block;
  font-size: 20px;
  min-width:108px;
  color: ${(props) => (props.selected ? '#000' : '#9e9e9e')}; /* Lighter color for non-selected day text */
`;

const DateNumber = styled.span`
  display: block;
  font-size: 50px;
  font-weight: bold;
  margin-top: 5px;
  color: #000;
  
`;

const Time = styled.span`
  display: block;
  font-size: 15px;
  
  margin-left: 0px;
  margin-top: 40px;
  color: #555;
`;
const SelectDate = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 600;
padding-left:10px;
padding-bottom:10px;

text-align: left;
color: rgba(26, 26, 26, 1);

`;


const BookButton = styled.button`
 background: rgba(0, 184, 0, 1);

  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 25px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px); /* Ensure it fits within the padding of the container */
  max-width: 400px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2bbbad;
  }
`;
const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;
  
   

`;
const NavIcon = styled.div`
padding-top:20px

`;
const ModalTitleConatiner = styled.h2`
display:flex; 
flex-direction:column; 
padding-left:20px
`;
const ModalTitle = styled.div`
display:flex; 
font-family: Poppins;
color: #1A1A1A; 
font-size: 25.24px;
font-weight: bolder; 
line-height: 23.09px;
text-align: left;

`;

const ServiceTitle = styled.div`
display:flex; 
color: rgba(154, 148, 148, 1);
font-family: Poppins;
font-size: 16px;
font-weight: 400;

text-align: left;


`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 6px;
  right: 6px;
  max-height: 80%;
  min-height: 80%;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  z-index: 1000;
  
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  
  
  max-height: 60%;
  background-color: white;
  
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

const FarmContainer = styled.div`
font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: left;
min-height:60%;
max-height:60%;  

`;

const TitleContainer = styled.div`
  font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: left;


`;
