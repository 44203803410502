import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import seedIcon from '../../asset/images/seedIcon.svg';
import fertiliserIcon from '../../asset/images/fertiliserIcon.svg';
import pesticidesIcon from '../../asset/images/pesticidesIcon.svg';
import toolIcon from '../../asset/images/toolIcon.svg';
import Product_Video from '../../asset/images/ProductVideo.svg';
import whiteRightArrow from '../../asset/images/white-right-arrow.svg';
import Modal from 'react-modal'; // React Modal library
import leftNavArrow from '../../asset/images/leftNavArrow.svg';
import Stores from './Stores';
import ProductStores from './ProductStores';

Modal.setAppElement('#root'); // Set the app root element for accessibility

const Container = styled.div`
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(143, 203, 66, 0.01) 0%, rgba(71, 166, 72, 0) 100%);

  border-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Arial, sans-serif;
    height: 100vh; /* Full height of the viewport */
    

`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  margin-bottom: 10%;
  overflow-y: auto; /* Enable vertical scrolling */
overflow-x:hidden;
  /* Default max-height */
    height: 100vh; /* Full height of the viewport */ 
    
`;





const ModalContent = styled.div`
  padding: 0px;
  background: #F8FAF5;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
const NavIcon = styled.div`

position: absolute;
  top: 40px;
  left: 20px;
  z-index:1 ;


`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


const ServicesTitle = styled.div`
  margin-top: 10px;
  font-size: 20px;
  color: #000;
  font-family: Poppins;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;
padding-left:2px;
padding-bottom:2px;
`;

const ProductCategoryList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const ProductCategoryContainer = styled.div`
  width: 48%;
  height: 150px; 
  border-radius: 8px; 
  box-shadow: 0px 9.6px 23.04px 0px #0000001A;   
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Initial state to be invisible */
  animation: ${fadeIn} 0.6s forwards; /* Apply the fade-in animation */
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
  background:white;
`;

const ProductCategoryImage = styled.img`
  width: 60px; /* Adjust as needed */
  height: auto;
  object-fit: cover;
`;

const ProductCategoryName = styled.div`
  margin-top: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #1A1A1A;
;
`;

const ModalTitle = styled.h2`
display:flex;
 
font-family: Poppins;
color: #1A1A1A;

font-family: Poppins;
font-size: 25.24px;
font-weight: bolder; /* Use 'bolder' for extra bold */
line-height: 23.09px;
text-align: left;

`;
const ModalNavigation = styled.div`
  width: 100%;  
  flex-direction: row;
  display:flex;    
  
  max-height:70px
`;
const ModalTitleConatiner = styled.h2`
display:flex; 
flex-direction:column; 
padding-left:70px
`;

const CategoryFilters = styled.div`
  display: flex;
  
  gap: 5px; 
    justify-content: center;   /* Center horizontally */
  align-items: flex-start;   /* Align items at the top */
    

`;

// Button styling for both active and inactive states
const CategoryButton = styled.div`
  padding: 8px 20px;
  border-radius: 20px;
  border: 2px solid #43cb65;
  background-color: ${props => (props.active ? '#00B800' : '#FFFFFF')};
  color: ${props => (props.active ? '#fff' : '#979797')};
  font-size: 14px;
  cursor: pointer;
  outline: none;

  &:focus, &:active {
    outline: none; /* Disable the focus outline */
    transform: none; /* Disable click animation (like scale) */
    box-shadow: none; /* Disable shadow changes on click */
  }
  &:hover {
    background-color: ${props => (props.active ? '#43cb65' : '#e6f8ea')};
  }

  transition: background-color 0.3s ease;
`;
const BannerContainer = styled.div`
margin-top:30px;
   margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: 300px;
  marigin-left: 20px;  
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #F8FAF5;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px; /* Ensure the border-radius matches the container */
  overflow-x: hidden;
  overflow-y: hidden;
  
`;

// Text overlay for the promotional message
const BannerText = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Shadow for readability */
  b
`;
// Container for the grid with two columns per row
const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of items to next row */
  justify-content: space-between; /* Spaces out the items evenly */
  padding: 0px;
  box-sizing: border-box;
  margin-bottom:10px;
  margin-top:10px;
   
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to keep items in a single row */
  justify-content: flex-start; /* Align items to the left */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 100px;

  /* Hide the default scrollbar for WebKit browsers (like Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Add padding or gap between items */
  gap: 10px;
  
  /* Add smooth scrolling */
  scroll-behavior: smooth;
`;
// Card style for individual items
const Card = styled.div`
  background-color: white; 
  border-radius: 12px;
  padding: 20px;
  margin-top:10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%; /* Ensures the card stretches to full height */
  width:140px;
  min-height: 250px; /* Ensures the card stretches to full height */
`;

// Image inside the card
const CardImage = styled.img`
  width: 148px;  /* Adjusted image size to match your design */
  height: auto;
  margin-bottom: 15px;
`;

// Title of the product
const CardTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Poppins;
  color: #333;
  min-height:60px;
  line-height: 1.2; /* Adjusted line-height for better text readability */
`;

// Description or additional details
const CardDescription = styled.p`
  font-size: 16px;
  color: #555;
  font-family: Poppins;
  margin: 0; 
  min-height:20px;
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  display: block;
  border-radius: 12px;
  
`;

const ProductInfo = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const CategoryTag = styled.span`
  display: inline-block;
  background-color: #ff6347;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex: row;
  margin-top: 10px;
`;

const SelectLabel = styled.div`
font-family: Poppins;
font-size: 14px;

line-height: 20px;
font-weight: 900;
text-align: left;
color:#1A1A1A;

`
const SelectBox = styled.div`
  flex: 1;
  margin-right: 10px;

  

  select {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 2px solid #A5A5A5;
    font-size: 14px;
    font-weight: 900;
    cursor: pointer;
    height:43px;
    background:white;
    
    
  }
`;

const QuantitySelector = styled.div`
  flex: 1;

  label {
    display: block;
    font-size: 12px;
    color: #666;
  }

  input {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: 2px solid #43cb65;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
`;

const ActionButton = styled.button`
  width: 100%;
  background-color: #00B800;

  color: white;
  padding: 16px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  font-family: Poppins;
font-size: 17px;
font-weight: 600;
line-height: 21px;
text-align: center;


  &:hover {
    background-color: #00b800;
  }
`;

const ProductVideo = styled.div`
  position: relative;
  margin-top: 20px;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  
  img {
    width: 100%;
    border-radius: 12px;
    display: block;
      object-fit: cover; /* Or use other values like contain, fill, etc. */

  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; /* Size of YouTube button */
    height: 50px;
    cursor: pointer;
  }
`;


const SimilarProducts = styled.div`
  margin-top: 30px;

  h3 {
    font-size: 18px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-top: 10px;
`;

const ProductCard = styled.div`
  width: 120px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  background-color: white;

  img {
    width: 100%;
    border-radius: 8px;
  }

  p {
    margin: 5px 0;
  }
`;

const Label = styled.div`
  background-color: #D32F12;
  color: white;
  padding: 5px 10px;
  border-radius: 8px; 
  font-weight: 200; 
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: Montserrat;
  font-size: 9px; 
  letter-spacing: 1px;
  text-align: left;

`;
const CardContainer1 = styled.div`
  background-color: #f0fdf4; /* Light greenish background */ 
  
  border-radius: 10px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
`;
const ProductTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align:left;
  font-family: Poppins;
font-size: 28px;
font-weight: 600;
line-height: 42px;
text-align: left;

`;
const SubTitle = styled.div`
  color: rgba(26, 26, 26, 1); 
  font-family: Poppins;
font-size: 17px;
font-weight: 600;

text-align: left;
padding-top:1px

`;
const MRPBox = styled.div`
display:flex;
flex-direction:row;
padding-top:10px

`;
const MRPLabel = styled.div`
 font-family: Indian Rupee;
font-size: 24px;
font-weight: 400;
line-height: 36px;
text-align: left;

`;
const MRP = styled.div`
font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 36px;
text-align: left;
padding-left:10px;

`;
const ProductDescription = styled.div`
  font-size: 14px;
  color: #1A1A1A;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;
letter-spacing: 0.80000000298023224px;
padding-top:10px

`;


const RatingSection = styled.div`
  
  align-items: right;
  font-size: 16px;
  margin-right: 16px;
  
  font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 36px;
letter-spacing: -0.20000000298023224px;
text-align: left;


`;

const Rating = styled.span`
  margin-right: 5px;
  
  
`;

const StarIcon = styled.span`
  color: gold;
  font-size: 25px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  width: 170px;
  justify-content: space-between;
  background:white; 
    padding: 8px;
    border-radius: 8px;
    border: 2px solid #A5A5A5;
    font-size: 14px;
    cursor: pointer;
`;

const Button = styled.button`
  background: none;
  border: none;
  font-size: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #1A1A1A;
  &:focus {
    outline: none;
  }
`;

const NumberDisplay = styled.span`
font-family: Poppins;
font-size: 20px;
font-weight: 900;
line-height: 24px;
text-align: left;
color:#1A1A1A
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 999;
`;

const PopupContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 6px;
  right: 6px;
  max-height: 50%;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  padding:14px 20px 3px 5px;
  
  font-family: Poppins;
font-size: 25px;
font-weight: 900;
line-height: 24px;
text-align: left;
letter-spacing: 1px;
color:#1A1A1A;

`;

const ProductCategory = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  const [startVideo, setStartVideo] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const categories = ['All', 'Fertilizers', 'Pesticides', 'Tools'];

  const [count, setCount] = useState(1);
  const increase = () => setCount(count + 1);
  const decrease = () => setCount(count > 0 ? count - 1 : 0);
  const products = [
    {
      id: 1,
      title: "Magalam Seeds Limited - 5240 Bajra Seed",
      description: "Dose: 250gm/Acre",
      img: "https://www.bigbasket.com/media/uploads/p/xl/40200389-2_3-farmland-frozen-vegetables-green-peas.jpg", // replace with actual image paths
    },
    {
      id: 2,
      title: "Samriddhi Vegetable Seeds",
      description: "Dose: 250gm/Acre",
      img: "https://m.media-amazon.com/images/I/81I-XKvt01L.jpg",
    },
    {
      id: 1,
      title: "Magalam Seeds Limited - 5240 Bajra Seed",
      description: "Dose: 250gm/Acre",
      img: "https://www.bigbasket.com/media/uploads/p/xl/40200389-2_3-farmland-frozen-vegetables-green-peas.jpg", // replace with actual image paths
    },
    {
      id: 2,
      title: "Samriddhi Vegetable Seeds",
      description: "Dose: 250gm/Acre",
      img: "https://m.media-amazon.com/images/I/81I-XKvt01L.jpg",
    },
    {
      id: 1,
      title: "Magalam Seeds Limited - 5240 Bajra Seed",
      description: "Dose: 250gm/Acre",
      img: "https://www.bigbasket.com/media/uploads/p/xl/40200389-2_3-farmland-frozen-vegetables-green-peas.jpg", // replace with actual image paths
    },
    {
      id: 2,
      title: "Samriddhi Vegetable Seeds",
      description: "Dose: 250gm/Acre",
      img: "https://m.media-amazon.com/images/I/81I-XKvt01L.jpg",
    },
    {
      id: 1,
      title: "Magalam Seeds Limited - 5240 Bajra Seed",
      description: "Dose: 250gm/Acre",
      img: "https://www.bigbasket.com/media/uploads/p/xl/40200389-2_3-farmland-frozen-vegetables-green-peas.jpg", // replace with actual image paths
    },
    {
      id: 2,
      title: "Samriddhi Vegetable Seeds",
      description: "Dose: 250gm/Acre",
      img: "https://m.media-amazon.com/images/I/81I-XKvt01L.jpg",
    },

  ];


  const openModal = () => {
    setIsModalOpen(true);
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation will trigger only once
    threshold: 0.1, // Trigger animation when 10% of the element is in view
  });

  return (
    <>
      <ServicesTitle>
        <TitleContainer>
          <ServicesTitle>{t('gaoON Products')}</ServicesTitle>
        </TitleContainer>
      </ServicesTitle>
      <ProductCategoryList>
        <ProductCategoryContainer ref={ref} style={{ opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' }}>
          <ProductCategoryImage src={seedIcon} onClick={() => { setIsModalOpen(true) }} />
          <ProductCategoryName>{t('Seeds')}</ProductCategoryName>
        </ProductCategoryContainer>
        <ProductCategoryContainer ref={ref} style={{ opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' }}>
          <ProductCategoryImage src={fertiliserIcon} onClick={() => { setIsModalOpen(true) }} />
          <ProductCategoryName>{t('Fertilisers')}</ProductCategoryName>
        </ProductCategoryContainer>
        <ProductCategoryContainer ref={ref} style={{ opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' }}>
          <ProductCategoryImage src={pesticidesIcon} onClick={() => { setIsModalOpen(true) }} />
          <ProductCategoryName>{t('Pesticides')}</ProductCategoryName>
        </ProductCategoryContainer>
        <ProductCategoryContainer ref={ref} style={{ opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(20px)' }}>
          <ProductCategoryImage src={toolIcon} onClick={() => { setIsModalOpen(true) }} />
          <ProductCategoryName>{t('Tools')}</ProductCategoryName>
        </ProductCategoryContainer>
      </ProductCategoryList>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Prod"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          {isModalOpen && <Container>
            <ModalNavigation>
              <NavIcon >
                {<img src={leftNavArrow} onClick={closeModal} />}
              </NavIcon>
              <ModalTitleConatiner>
                <ModalTitle>Product Categories</ModalTitle>
              </ModalTitleConatiner>
            </ModalNavigation>

            <Content>
              <CategoryFilters>
                {categories.map(category => (
                  <CategoryButton
                    key={category}
                    active={activeCategory === category}
                    onClick={() => setActiveCategory(category)}
                  >
                    {category}
                  </CategoryButton>
                ))}
              </CategoryFilters>
              <BannerContainer>
                <BannerImage
                  src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/agriculture-flyer-design-template-60b9311404eff9ab0d10903ad5f7ceb2_screen.jpg?ts=1648546470"
                  alt="Promotional Banner"
                />
                <BannerText>Promotional banner</BannerText>
              </BannerContainer>
              <GridContainer>
                {products.map((product) => (
                  <Card key={product.id} onClick={() => { setIsProductOpen(true) }} >
                    <CardImage src={product.img} alt={product.title} />
                    <CardTitle>{product.title}</CardTitle>
                    <CardDescription>{product.description}</CardDescription>
                  </Card>
                ))}
              </GridContainer>
              <Stores />
              <div style={{ height: 100 }} ></div>

            </Content>
          </Container>}


        </ModalContent>
      </Modal>
      <Modal
        isOpen={isProductOpen}
        onRequestClose={closeModal}
        contentLabel="Prod"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            inset: '0',
            padding: '0',
            borderRadius: '0',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: 'linear-gradient(180deg, rgba(143, 203, 66, 0.14) 0%, rgba(71, 166, 72, 0) 100%)'
          },
        }}
      >
        <ModalContent>
          {startVideo && <Iframe
            src={"https://www.youtube.com/embed/RRrtIY-9i7c?autoplay=1"}
            title="YouTube video"
            allow="autoplay; fullscreen"
          />
          }

          {!startVideo && <Container>
            <ModalNavigation>
              <NavIcon >
                {!startVideo && <img src={leftNavArrow} onClick={() => { setIsProductOpen(false); setIsModalOpen(true) }} />}
                {startVideo && <img width={1000} src={whiteRightArrow} onClick={() => setStartVideo(false)} />}
              </NavIcon>
              {!startVideo && <ModalTitleConatiner>
                <ModalTitle>Vadh 505</ModalTitle>
              </ModalTitleConatiner>
              }
            </ModalNavigation>

            <Content>
              <ProductImage src="https://www.bigbasket.com/media/uploads/p/xl/40200389-2_3-farmland-frozen-vegetables-green-peas.jpg" alt="Product" />
              <ProductInfo>


                <Label>INSECTICIDE</Label>
                <CardContainer1>
                  {/* Product Title and Rating */}
                  <div >
                    <div style={{ display: 'flex', flexDirection: "row", width: "100%" }} >
                      <ProductTitle style={{ width: "90%" }} >Vadh 505</ProductTitle>
                      <RatingSection style={{ width: "10%" }}>
                        <Rating>3.5</Rating>
                        <StarIcon>★</StarIcon>
                      </RatingSection>

                    </div>
                    <SubTitle>Chlorpyriphos 50% + Cypermethrin 5% EC</SubTitle>
                  </div>

                  {/* MRP */}
                  <MRPBox>
                    <MRPLabel>MRP:</MRPLabel>
                    <MRP> ₹240</MRP>
                  </MRPBox>


                  <ProductDescription>
                    American Bollworm, Spotted Bollworm, Pink Bollworm, Spodoptera Litura, Aphid, Jassid, Thrips, White Fly etc.
                  </ProductDescription>
                </CardContainer1>

                <SelectionContainer>
                  <SelectBox>
                    <SelectLabel>PACKING</SelectLabel>
                    <select>
                      <option>1 LITRE</option>
                      <option>2 LITRES</option>
                    </select>
                  </SelectBox>
                  <QuantitySelector>
                    <SelectLabel>QUANTITY</SelectLabel>
                    <InputContainer>
                      <Button onClick={decrease}>−</Button>
                      <NumberDisplay>{count}</NumberDisplay>
                      <Button onClick={increase}>+</Button>
                    </InputContainer>
                  </QuantitySelector>
                </SelectionContainer>
                <ActionButton onClick={() => { setIsConfirm(true) }}>Like to Buy</ActionButton>

                <ProductVideo>
                  {/* Background Image */}
                  <img src={Product_Video} alt="Video thumbnail" />

                  {/* YouTube Play Button */}
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
                    alt="Play button"
                    onClick={() => { setStartVideo(true) }}
                    className="play-button"
                  />
                </ProductVideo>
              </ProductInfo>
              <SimilarProducts>
                <h3>Similar Products</h3>
                <HorizontalContainer>
                  {products.map((product) => (
                    <Card key={product.id} onClick={() => { setIsProductOpen(true) }} >
                      <CardImage src={product.img} alt={product.title} />
                      <CardTitle>{product.title}</CardTitle>
                      <CardDescription>{product.description}</CardDescription>
                    </Card>
                  ))}
                </HorizontalContainer>
              </SimilarProducts>

            </Content>
          </Container>}
          {isConfirm && (
            <Backdrop >
              <PopupContainer onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={() => {
                  setIsModalOpen(false);
                  setIsProductOpen(false);
                  setActiveCategory('All');
                  setStartVideo(false);
                  setIsConfirm(false);
                }} >×</CloseButton>
                <ContentContainer>
                  Thanks for your interest !
                </ContentContainer>
                <ContentContainer>
                  Please connect to:       
                </ContentContainer>
                <ProductStores />
              </PopupContainer>
            </Backdrop>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};


export default ProductCategory;
